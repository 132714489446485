<template>
  <transition name="fade" v-if="qrActive">
    <section class="screen-modal qr-code-modal">
      <div class="modal-backdrop"></div>
      <div class="modal-content">
        <section class="modal-inner-content">
          <div ref="qrVideo" id="reader" class="reader"></div>
          <template v-if="cameras.length">
            <h5 class="reader-title">
              {{ displayLabelName("plan", "plan", "select-a-camera") }}
            </h5>
          </template>
        </section>
        <footer class="modal-footer">
          <ul class="cameras" :class="{ active: camerasMenu }">
            {{
              cameras
            }}
            <li v-for="camera in cameras" :key="camera.id" class="camera-item">
              <button class="scan-button" @click="getUserMedia(camera.id)">
                {{ camera.label }}
              </button>
            </li>
          </ul>
          <nav class="actions-menu theme-gray">
            <ul class="actions-list">
              <li class="action">
                <button class="action-btn" @click="cancelStream">
                  {{ displayLabelName("plan", "plan", "cancel") }}
                </button>
              </li>
              <template v-if="streaming && zoom">
                <li class="action">
                  <button class="action-btn" @click="increaseZoom">
                    {{ displayLabelName("plan", "plan", "zoom-in") }}
                  </button>
                </li>
                <li class="action">
                  <button class="action-btn" @click="deceaseZoom">
                    {{ displayLabelName("plan", "plan", "zoom-out") }}
                  </button>
                </li>
              </template>
              <li
                class="action"
                v-if="cameras && cameras.length > 0 && streaming"
              >
                <button class="action-btn" @click="toggleCameraMenu">
                  {{ displayLabelName("plan", "plan", "camera") }}
                </button>
              </li>
            </ul>
          </nav>
        </footer>
      </div>
    </section>
  </transition>
</template>

<script>
import { errorHandler } from "@/services/error-handler";
import { mapActions, mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { BrowserQRCodeReader } from "@zxing/browser";

export default {
  name: "htmlQrCodeInterface",
  data() {
    return {
      codeReader: new BrowserQRCodeReader(),
      cameras: [],
      scanData: null,
      camerasMenu: false,
      zoom: null,
      zoomMin: 0,
      zoomMax: 0,
      zoomStep: 0,
      track: null,
      supportsCameraApi:
        "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices,
      streaming: false,
    };
  },
  created() {
    this.selectPlanResource(null);
    this.selectPlanLevel(null);
  },
  mounted() {
    this.$nextTick(async () => {
      let u = await this.getUserMedia();
      let d = await this.getDevices();
      console.log(u);
      console.log(d);
    });
  },
  watch: {
    $route() {
      this.cancelStream();
    },
    scanData() {
      this.fetchQrCodeData(this.scanData);
      return this.cancelStream();
    },
    // resetuj level i resurs kad se ponovo udje u komponentu kao i selektovani level i resurs za plan
  },
  computed: {
    ...mapState("qrCode", ["qrActive", "recordingStarted"]),
    ...mapState("resource", ["resource"]),
    routeName() {
      return this.$route.name;
    },
    // this.getLevel(this.routeParam);
  },
  methods: {
    ...mapActions("plan", [
      "selectPlanLevel",
      "selectPlanResource",
      "selectPlanFrequentResourceType",
      "setScreenNum",
      "planScan",
    ]),
    ...mapActions("resource", ["getResource"]),
    async getLevel(levelId) {
      return httpServiceAuth.get(`${apiEndpoints.company.levels}/${levelId}`, {
        params: {
          includes: ["all_parents"],
        },
      });
    },
    async getResource(resourceId) {
      return httpServiceAuth.get(
        `${apiEndpoints.company.resources}/${resourceId}`,
        {
          params: {
            includes: ["all_parents"],
          },
        }
      );
    },
    async goToPlan(id, type) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      if (Number(type) === 1) {
        // resource
        try {
          const resource = await this.getResource(id);
          if (resource && resource.data && resource.data.data) {
            if (resource.data.data.level_id) {
              this.selectPlanLevel({
                includes: ["default", "directly_declarable", "resources_count"],
                level_id: resource.data.data.level_id,
              }).then(() => {
                this.selectPlanResource(resource.data.data);
                this.$router.push({
                  name: "r_plan-resources-slots",
                  params: {
                    one_click: this.routeName === "r_one-click" ? true : false,
                  },
                });
              });
            } else {
              this.selectPlanResource(resource.data.data);
              this.$router.push({
                name: "r_plan-resources-slots",
                params: {
                  one_click: this.routeName === "r_one-click" ? true : false,
                },
              });
            }
          }
        } catch (error) {
          if (error && error.response) {
            errorHandler(error.response);
          }
        } finally {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        }
      } else if (Number(type) === 2) {
        // level
        try {
          const level = await this.getLevel(id);
          if (level && level.data && level.data.data) {
            this.selectPlanLevel({
              includes: ["default", "directly_declarable", "resources_count"],
              level_id: level.data.data.id,
            });
            this.$router.push({ name: "r_plan-resources-slots" });
          }
        } catch (error) {
          if (error && error.response) {
            errorHandler(error.response);
          }
        } finally {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        }
      } else if (Number(type) === 3 || Number(type) === 4) {
        this.$store.commit("loader/setScreenLoading", false, { root: true });
        this.$router.push({
          name: "r_actions",
          params: {
            declaration_id: id,
            one_click: this.routeName === "r_one-click" ? true : false,
          },
        });
      } else {
        // error
        this.displayGenericMessage();
        this.$store.commit("loader/setScreenLoading", false, { root: true });
        this.closeAll();
      }
    },
    async fetchQrCodeData(url) {
      let qrCode = "";

      if (!url.includes(window.location.origin)) {
        qrCode = url;
      } else {
        qrCode = url.split("/").pop();
      }
      // services
      if (this.routeName.includes("r_services")) {
        this.$store.commit("loader/setScreenLoading", true, { root: true });
        return httpServiceAuth
          .post(`${apiEndpoints.company.resources}/checkservices`, {
            qr_code: qrCode,
          })
          .then((response) => {
            this.$store.commit(
              "service/setServiceResource",
              response.data.success,
              {
                root: true,
              }
            );
            this.$router.push({
              name: "r_services-add-order",
              params: {
                order_resource_id: response.data.success,
              },
            });
          })
          .catch((error) => {
            if (error.response) {
              errorHandler(error.response);
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true,
            });
          });
      }
      // update resource qr code
      else if (this.routeName.includes("r_edit-resource-qr-code")) {
        this.$store.commit("loader/setScreenLoading", true, { root: true });
        return httpServiceAuth
          .post(
            `${apiEndpoints.company.resources}/${this.resource.data.id}/updateqrcode`,
            {
              qr_code: qrCode,
            }
          )
          .then(() => {
            this.$store.dispatch(
              "resource/getResource",
              { id: this.resource.data.id },
              { root: true }
            );
          })
          .catch((error) => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true,
            });
          });
      }
      // clean
      else if (this.routeName.startsWith("r_clean")) {
        this.$store.commit("clean/setCleanQrCode", qrCode, { root: true });
        if (this.routeName.includes("map")) {
          this.$router.push({
            name: "r_clean-resources-map-clean",
          });
        } else {
          this.$router.push({
            name: "r_clean-resources-clean",
          });
        }
      }
      // plan
      else {
        httpServiceAuth
          .get(`${apiEndpoints.company.qrCodeScan}`, {
            params: { qr_code: qrCode },
          })
          .then((response) => {
            if (response.data && response.data.data) {
              const {
                declaration_id,
                level_id,
                resource_id,
              } = response.data.data;

              let codeType;

              if (declaration_id) {
                codeType = 4;
              } else if (level_id) {
                codeType = 2;
              } else if (resource_id) {
                codeType = 1;
              }

              this.goToPlan(
                declaration_id || level_id || resource_id,
                codeType
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              errorHandler(error.response);
            }
          });
      }
    },
    displayGenericMessage(error) {
      return errorHandler({
        errorObject: {
          data: {
            message: error
              ? error
              : this.displayLabelName("plan", "plan", "qr-code-error"),
          },
        },
      });
    },
    setZoomOptions(track) {
      const capabilities = track.getCapabilities();
      const settings = track.getSettings();
      if ("zoom" in settings) {
        this.zoom = settings.zoom;
        this.zoomMin = capabilities.zoom.min;
        this.zoomMax = capabilities.zoom.max;
        this.zoomStep = capabilities.zoom.step;
      }
    },
    increaseZoom() {
      if (this.zoom < this.zoomMax) {
        this.zoom += this.zoomStep;
        this.track.applyConstraints({
          advanced: [{ zoom: this.zoom }],
        });
      }
    },
    deceaseZoom() {
      if (this.zoom > this.zoomMin) {
        this.zoom -= this.zoomStep;
        this.track.applyConstraints({
          advanced: [{ zoom: this.zoom }],
        });
      }
    },
    async getUserMedia(deviceId) {
      this.camerasMenu = false;

      if (this.supportsCameraApi) {
        let constraints = {
          audio: false,
          video: {
            deviceId: { exact: deviceId },
            focusMode: "continuous",
          },
        };

        try {
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          const videoTracks = stream.getVideoTracks();

          // Always use the first video track as it has been pre-selected
          this.track = videoTracks[0];

          this.setZoomOptions(this.track);
          this.handleStream(stream);

          return stream; // Resolve the promise with the stream
        } catch (err) {
          this.displayGenericMessage(err);
          throw err; // Reject the promise with the error
        }
      }
    },
    async initializeCamera() {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        return true; // Camera permission granted
      } catch (err) {
        console.error("Camera permission denied or an error occurred:", err);
        return false; // Camera permission denied
      }
    },
    async listAndSelectCamera() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );

        // Find the deviceId for the default camera
        let selectedDeviceId = null;

        // If no default camera or match found, use the first camera
        if (!selectedDeviceId && videoDevices.length > 0) {
          selectedDeviceId = videoDevices[0].deviceId;
        }

        // Return the selected deviceId
        return selectedDeviceId;
      } catch (error) {
        console.error("Error listing/selecting cameras:", error);
      }
    },
    async getDevices() {
      if (this.supportsCameraApi) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = [];
        devices.forEach((device) => {
          if (device.kind === "videoinput") {
            videoDevices.push({
              id: device.deviceId,
              label: device.label,
            });
          }
        });
        this.cameras = videoDevices;
      }
    },
    handleStream(stream) {
      const self = this;
      const element = this.$refs.qrVideo;
      let video = element.querySelector("video");

      if (!video) {
        video = document.createElement("video");
        video.style.width = "100%";
        video.style.height = "100%";
        video.setAttribute("muted", true);
        video.playsInline = true;
        element.append(video);
        video.addEventListener("canplay", self.setStreaming, false);
      }
      video.srcObject = stream;
      video.play();
    },
    setStreaming() {
      if (!this.streaming) {
        const video = this.$refs.qrVideo.querySelector("video");
        let width = 373;
        let height = 0;
        height = video.videoHeight / (video.videoWidth / width);
        video.setAttribute("width", width);
        video.setAttribute("height", height);
        this.streaming = true;
        this.decodeOnce();
      }
    },
    resetState() {
      this.cameras = [];
      this.streaming = false;
      this.zoom = null;
      this.zoomMin = 0;
      this.zoomMax = 0;
      this.zoomStep = 0;
      this.track = null;
      this.$store.commit("qrCode/setQrActive", false, { root: true });
      this.$store.commit("qrCode/setQrScreenId", null, { root: true });
    },
    cancelStream() {
      const videoEl = this.$refs.qrVideo.querySelector("video");
      this.track && this.track.stop();
      if (videoEl) {
        videoEl.removeEventListener("canplay", this.setStreaming);
        videoEl.remove();
      }
      this.resetState();
    },
    async toggleCameraMenu() {
      if (!this.camerasMenu) {
        this.track.stop();
      }
      this.camerasMenu = !this.camerasMenu;
    },
    async decodeOnce() {
      const el = this.$refs.qrVideo.querySelector("video");
      this.codeReader.scan(el, (result, _, controls) => {
        // use the result and error values to choose your actions
        // you can also use controls API in this scope like the controls
        // returned from the method.
        if (result) {
          controls.stop();
          this.scanData = result.getText();
        }
      });
    },
  },
  beforeUnmount() {
    this.cancelStream();
  },
};
</script>

<style scoped>
.reader {
  width: 373px !important;
}
</style>
